<!-- eslint-disable global-require -->
<!-- eslint-disable global-require -->
<template>
  <!-- <b-row class="match-height">
    <b-col
      sm="12"
      md="3">
      <b-card
        bg-variant="primary"
        align="center"
        class="card-font">
        <p>
          <span class="card-total">
            {{ items.open }}
          </span>
        </p>
        <span class="card-title">
          Open
        </span>
      </b-card>
    </b-col>
    <b-col
      sm="12"
      md="3">
      <b-card
        bg-variant="success"
        align="center"
        class="card-font">
        <p>
          <span class="card-total">
            {{ items.closed }}
          </span>
        </p>
        <span class="card-title">
          Closed
        </span>
      </b-card>
    </b-col>
    <b-col
      sm="12"
      md="3">
      <b-card
        bg-variant="danger"
        align="center"
        class="card-font">
        <p>
          <span class="card-total">
            {{ items.overDue }}
          </span>
        </p>
        <span class="card-title">
          Over SLA
        </span>
      </b-card>
    </b-col>
    <b-col
      sm="12"
      md="3">
      <b-card
        bg-variant="warning"
        align="center"
        class="card-font">
        <p>
          <span class="card-total">
            {{ items.void }}
          </span>
        </p>
        <span class="card-title">
          Void
        </span>
      </b-card>
    </b-col>
  </b-row> -->
  <b-row>
    <b-col
      v-for="(item,index) in status"
      :key="index"
      class="mb-2"
      cols="12"
      md="3">
      <div
        v-if="item.title === 'Open'"
        class="cards d-flex"
      >
        <div
          class="card-left-icon d-flex justify-content-center align-items-center"
          :style="`background: ${item.color} !important`">
          <div>
            <img :src="item.icon">
          </div>
        </div>
        <div
          style="width:100%;"
          class="d-flex flex-column justify-content-center px-2">
          <div
            class="card-title"
            :style="`color: ${item.color} !important`">
            {{ item.title }}
          </div>
          <div class="card-total">
            <div class="d-flex justify-content-center align-items-center">
              <div
                class="d-flex flex-column justify-content-center align-items-center cursor-pointer"
                @click="openTodayTotal()">
                <div class="text-primary">
                  {{ item.total }}
                </div>
                <div class="subtitle">
                  Today
                </div>
              </div>
              <div class="mx-2">
                |
              </div>
              <div
                class="d-flex flex-column justify-content-center align-items-center cursor-pointer"
                @click="openTotalPage()">
                <div class="text-primary">
                  {{ items.openTotal }}
                </div>
                <div class="subtitle">
                  Total
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="cards d-flex "
      >
        <div
          class="card-left-icon d-flex justify-content-center align-items-center"
          :style="`background: ${item.color} !important`">
          <div>
            <img :src="item.icon">
          </div>
        </div>
        <div class="d-flex flex-column justify-content-center px-2">
          <div
            class="card-title"
            :style="`color: ${item.color} !important`">
            {{ item.title }}
          </div>
          <div
            class="card-total text-primary cursor-pointer"
            @click="item.to()">
            {{ item.total }}
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    items: {
      type: Object,
      default: () => ({
        open: 0,
        openTotal: 0,
        closed: 0,
        void: 0,
        overDue: 0
      })
    }
  },
  computed: {
    status () {
      return [
        {
          title: 'Open',
          total: this.items.open || 0,
          color: '#7367EF',
          to: () => this.$router.push({
            name: 'case',
            query: {
              caseStatus: 'open',
              startDate: dayjs().subtract(3, 'M').format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD') } }),
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/open_status.svg')
        },
        {
          title: 'Closed',
          total: this.items.closed || 0,
          color: '#29C770',
          to: () => this.$router.push({
            name: 'case',
            query: {
              caseStatus: 'closed',
              startDate: dayjs().format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD') } }),
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/closed_status.svg')
        },
        {
          title: 'Over SLA',
          total: this.items.overDue || 0,
          color: '#E95354',
          to: () => this.$router.push({ name: 'case', query: { caseStatus: 'over Due' } }),
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/over_sla_status.svg')
        },
        {
          title: 'Void',
          total: this.items.void || 0,
          color: '#FF9E43',
          to: () => this.$router.push({
            name: 'case',
            query: {
              caseStatus: 'void',
              startDate: dayjs().format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD')
            } }),
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/icons/void_status.svg')
        }
      ]
    }
  },
  methods: {
    openTotalPage () {
      this.$router.push({ name: 'case',
        query: {
          caseStatus: 'open',
          startDate: dayjs().subtract(3, 'M').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        } })
    },
    openTodayTotal () {
      this.$router.push({ name: 'case',
        query: {
          caseStatus: 'open',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD')
        } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .cards {
    background: #ffffff ;
    width: 100% !important;
    height: 134px !important;
    border-radius: 6px;
  }

  .card-left-icon {
    background: #7367EF;
    width: 89px;
    height: 100%;
    border-radius: 6px 0px 0px 6px;
  }

  .card-font span {
    font-size: 24px;
    color: #7367EF !important;
  }

  .card-total {
    font-size: 32px !important;
    font-weight: 600;
    color: #625E6A;
  }

  .card-title {
    font-size: 24px;
    font-weight: 600;
    color: #7367EF !important;
  }

  .subtitle {
    font-size: 10px;
  }
</style>
