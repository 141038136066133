<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <CardStatistic
      :items="caseStatus"
      class="mb-2"
    />
    <!-- <div class="d-flex height-600">
      <div
        v-if="$checkRole(roleAnnouncementPost, profile.role)"
        class="w-100">
        <Announcement
          :group-service-options="groupService"
          @create-announcement="createAnnouncement($event)"
        />
      </div>
      <div
        v-if="filteredAnnouncements && filteredAnnouncements.length > 0"
        class="w-100 pl-2 overflow-y-scroll">
        <p>POST</p>
        <div id="post">
          <div
          >
            <b-card
              v-for="(announcement, index) in filteredAnnouncements"
              :key="`post-${index}`"
              class="mb-2"
            >
              <div
                v-if="checkRoleAndOwnPost(announcement, profile)"
                class="d-flex justify-content-end"
              >
                <b-button
                  class="mr-2"
                  :variant="checkSelectedAnnouncementIndex(index) ? 'primary' : 'warning'"
                  @click="handleFormAnnouncementEdit(index)"
                >
                  {{ checkSelectedAnnouncementIndex(index) ? 'ยกเลิก' : 'แก้ไข' }}
                </b-button>
                <b-button
                  variant="danger"
                  @click="deleteAnnouncement(announcement.id)">
                  ลบ
                </b-button>
              </div>
              <div v-if="!checkSelectedAnnouncementIndex(index)">
                <b-row class="mb-1">
                  <b-col>
                    <div>
                      <h3 class="d-block font-weight-medium text-xl">
                        {{ announcement.title }}
                      </h3>
                      <div
                        class="ql-editor"
                        v-html="announcement.description"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex">
                    <div class="mr-1">
                      <b-avatar
                        size="38"
                        variant="light-primary"
                        :text="symbolProfile(announcement.createdBy.username)"
                      />
                    </div>
                    <div>
                      <span class="d-block font-weight-semibold">{{ announcement.createdBy.username }}</span>
                      <span>{{ announcement.createdAt | formatDateEn }}</span>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <AnnouncementEdit
                v-else
                :group-service-options="groupService"
                :announcement-title="announcement.title"
                :announcement-description="announcement.description"
                :announcement-role="announcement.role"
                :announcement-group-service="announcement.groupService"
                @update:announcement-title="announcement.title = $event"
                @update:announcement-description="announcement.description = $event"
                @update:announcement-role="announcement.role = $event"
                @update:announcement-group-service="announcement.groupService = $event"
                @update-announcement="updateAnnouncement(announcement.id, $event)"
              />
            </b-card>
          </div>
        </div>
      </div>
    </div> -->
    <b-card class="mt-4">
      <div class="zoom-80">
        <b-table
          :items="items"
          :fields="fields"
          striped
          hover
          responsive
          @row-clicked="rowClick($event)">
          >
          <template #cell(incidentType)="data">
            <div v-if="data.item.tickeType && data.item.tickeType.name">
              {{ data.item.tickeType.name }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div v-if="data.item.createdAt">
              {{ data.item.createdAt | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(updatedAt)="data">
            <div v-if="data.item.updatedAt">
              {{ data.item.updatedAt | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(createdBy)="data">
            <div v-if="data.item && data.item.createdBy ">
              {{ data.item.createdBy.username }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(progressBy)="data">
            <div v-if="data.item && data.item.progressBy.firstName && data.item.progressBy.lastName ">
              {{ data.item.progressBy.firstName }} {{ data.item.progressBy.lastName }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(dueDate)="data">
            <div v-if="data.item.dueDate">
              {{ data.item.dueDate | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(resolveDate)="data">
            <div v-if="data.item.resolveDate">
              {{ data.item.resolveDate | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(caseStatus)="data">
            <b-badge :variant="$caseStatus(data.value)">
              {{ data.value | formatcaseStatus }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCard, BCol } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Announcement from '@/components/Dashboard/Announcement.vue'
// import AnnouncementEdit from '@/components/Dashboard/AnnouncementEdit.vue'
import CardStatistic from '@/components/Dashboard/CardStatistic.vue'
import AnnouncementProvider from '@/resources/AnnouncementProvider'
import TicketProvider from '@/resources/TicketProvider'
import GroupProvider from '@/resources/GroupProvider'
import PermissionAnnouncementPost from '@/common/permission/dashboard/announcement-post'

const AnnouncementService = new AnnouncementProvider()
const TicketService = new TicketProvider()
const GroupService = new GroupProvider()

export default {
  components: {
    BCard,
    // BAvatar,
    // BRow,
    BCol,
    // Announcement,
    CardStatistic
    // AnnouncementEdit
  },
  data () {
    return {
      announcements: null,
      content: '<p><a href="https://www.youtube.com/" rel="noopener noreferrer" target="_blank">Youtube</a></p><p><br></p><p>Gasdfsafsafas</p>',
      fields: [
        { key: 'code', label: 'Case No.', sortable: true },
        { key: 'incidentType', label: 'Case Type', sortable: true },
        { key: 'subject', label: 'subject', sortable: true },
        { key: 'priority', label: 'Priority', sortable: true },
        { key: 'customerName', label: 'CustomerName', sortable: true },
        { key: 'createdAt', label: 'CreateAt', sortable: true },
        { key: 'updatedAt', label: 'UpdatedAt', sortable: true },
        { key: 'progressBy', label: 'ProgressBy', sortable: true },
        { key: 'dueDate', label: 'DueDate', sortable: true },
        { key: 'resolveDate', label: 'ResolveDate', sortable: true },
        { key: 'caseStatus', label: 'Status', sortable: true }
      ],
      items: [],
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      caseStatus: {
        open: 0,
        closed: 0,
        void: 0,
        overDue: 0
      },
      roleAnnouncementPost: PermissionAnnouncementPost,
      groupService: [],
      selectedAnnouncementIndex: null,
      currentAnnouncementBeforeEdit: null
    }
  },
  computed: {
    filteredAnnouncements () {
      if (this.announcements && Array.isArray(this.announcements)) {
        return this.announcements.filter((announcement) => this.$checkRoleAndGroup(
          announcement.role,
          this.profile.role,
          announcement.groupService,
          this.profile.userGroupId
        ) || announcement.createdBy.id === this.profile.id)
      }
      return []
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    })
  },
  created () {
    // this.getAnnouncement()
    this.getGroupService()
    this.getTicket()
    this.getcaseStatus()
  },
  methods: {
    async getAnnouncement () {
      try {
        const data = await AnnouncementService.findAll()
        this.announcements = data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getGroupService () {
      try {
        const { data } = await GroupService.findAll(1, 9999)
        this.groupService = data
        this.groupService = this.groupService.filter((item) => item.status === 'active')
      } catch (error) {
        console.error(error)
      }
    },
    async getTicket () {
      try {
        const data = await TicketService.paginate(this.pagination.page, this.pagination.limit, { excludeStatus: 'closed', userId: this.profile.id })
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (error) {
        console.error(error)
      }
    },
    async getcaseStatus () {
      try {
        const { data } = await TicketService.findAllStatus(this.profile.id)
        this.caseStatus = data
      } catch (error) {
        console.error(error)
      }
    },
    async createAnnouncement (payload) {
      try {
        await AnnouncementService.create(payload)
        await this.getAnnouncement()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Post Announcement Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: 'Announcement has been posted'
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async updateAnnouncement (id, payload) {
      try {
        await AnnouncementService.update(id, payload)
        await this.getAnnouncement()
      } catch (error) {
        console.error(error)
      } finally {
        this.selectedAnnouncementIndex = null
      }
    },
    async deleteAnnouncement (id) {
      try {
        await AnnouncementService.remove(id)
        await this.getAnnouncement()
      } catch (error) {
        console.error(error)
      } finally {
        this.selectedAnnouncementIndex = null
      }
    },
    rowClick (val) {
      this.$router.push(`/case/detail/${val.id ? val.id : null}`)
    },
    symbolProfile (value) {
      return value.slice(0, 2)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getTicket()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    },
    handleFormAnnouncementEdit (index) {
      if (index === this.selectedAnnouncementIndex) {
        this.selectedAnnouncementIndex = null
        this.announcements[index] = this.currentAnnouncementBeforeEdit
      } else {
        this.currentAnnouncementBeforeEdit = { ...this.announcements[index] }
        this.selectedAnnouncementIndex = index
      }
    },
    checkSelectedAnnouncementIndex (index) {
      if (index === this.selectedAnnouncementIndex) {
        return true
      }
      return false
    },
    checkRoleAndOwnPost (announcement, profile) {
      const allowedRoles = ['super admin', 'supervisor']
      return (
        allowedRoles.includes(profile?.role)
        || (announcement?.createdBy?.id === profile?.id)
      )
    }
  }
}
</script>

<style scoped>
.card-body {
  padding-top: 32px;
  padding-bottom: 32px;
}
.ql-editor {
  padding: 0;
}
.zoom-80 {
  zoom: 80%;
}
</style>
